.embla1 {
  max-width: 48rem;
  /* margin: auto; */
  --slide-height: 10rem;
  --slide-spacing: 1rem;
  --slide-size: 75%;
}
.embla__viewport1 {
  overflow: hidden;
}
.embla__container1 {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide1 {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number1 {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* height: var(--slide-height); */
  user-select: none;
  /* width: 200px; */
}
.embla__controls1 {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  /* margin-top: 1.8rem; */
}
.embla__buttons1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}
