.embla1 {
  --slide-height: 10rem;
  --slide-spacing: 1rem;
  --slide-size: 75%;
  max-width: 48rem;
}

.embla__viewport1 {
  overflow: hidden;
}

.embla__container1 {
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
  display: flex;
}

.embla__slide1 {
  flex: 0 0 var(--slide-size);
  padding-left: var(--slide-spacing);
  min-width: 0;
  transform: translate3d(0, 0, 0);
}

.embla__slide__number1 {
  box-shadow: inset 0 0 0 .2rem var(--detail-medium-contrast);
  -webkit-user-select: none;
  user-select: none;
  border-radius: 1.8rem;
  align-items: center;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
}

.embla__controls1 {
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  display: grid;
}

.embla__buttons1 {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: .6rem;
  display: grid;
}
/*# sourceMappingURL=index.7e81fcb4.css.map */
